<template>
  <div :style="computedStyle" class="a_color_picker" v-show="!isHidden">
    <span class="registry_fields_label">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <div>
      <el-color-picker class="color_picker box1"
      :size="size"
      v-model="localValue"
      :disabled="readonly"
      @change="$emit('input', localValue)"></el-color-picker>
      <el-form-item
        :prop="name"
        :style="`width: ${this.width || `200px`}`"
        class="box"
        :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]">
        <el-input v-model="localValue"
                  :size="size"
                  :placeholder="placeholder || $locale.main.placeholder.string_field"
                  :name="name"
                  readonly
        ></el-input>
      </el-form-item>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
export default {
  name: 'a-color-picker',
  mixins: [mixin, VisibleMixin],
  props: {
    value: {
      frozen: true
    },
    size: {
      type: String,
      description: 'Размер'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    label: {
      description: 'Название',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    }
  },
  data () {
    return {
      localValue: this.value || '#409EFF'
    }
  },
  computed: {
    _isRequired () {
      if (this.isHidden) {
        return false
      }
      return this.required
    },
    computedStyle () {
      let css = this.CSS
      if (!this.block) {
        css += `;display: inline-block;`
      }
      if (this.block) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>
.a_color_picker .color_picker  {
  display: inline-block;
}
.a_color_picker .box {
  margin-bottom: -1px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
}
.a_color_picker .box1 {
  margin-bottom: -1px;
  display: inline-block;
  position: relative;
  top: 15px;
}
</style>
